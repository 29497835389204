<template>
  <div v-bind:class="colSizes" style="padding-bottom:50px">
    <a v-bind:href="item.link" v-if="item.link" @click.capture="clickHandler">
      <div class="card card-link">
        <div class="card-header">
          <h4 class="card-title"><i v-bind:class="classes"></i> {{item.name}}</h4>
        </div>
        <img v-if="item.logo" class="card-img-top" v-bind:src="item.logo"/>
        <div class="card-block">
          <p v-if="item.description" class="card-text">{{item.description}}</p>
          <slot name="card-content"></slot>
        </div>
      </div>
    </a>
    <div class="card" v-else>
      <div class="card-header">
        <h4 class="card-title"><i v-bind:class="classes"></i> {{item.name}}</h4>
      </div>
      <img v-if="item.logo" class="card-img-top" v-bind:src="item.logo"/>
      <div class="card-block">
        <p v-if="item.description" class="card-text">{{item.description}}.</p>
        <slot name="card-content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'info-card',
  props: {
    item: Object,
    size: String,
    clickCallback: Function
  },
  methods: {
    clickHandler(e) {
      if (this.clickCallback) {
        this.clickCallback(e, this.item.link);
      }
    }
  },
  computed: {
    classes: function() {
      if(this.item.icon) {
        return ['fa', this.item.icon];
      } else {
        return [''];
      }
    },
    colSizes: function() {
      if (this.size === "large") {
        return ['col-lg-6', 'col-md-6', 'col-sm-12', 'col-xs-12'];
      } else {
        return ['col-lg-3', 'col-md-4', 'col-sm-6', 'col-xs-12'];
      }
    }
  }
}
</script>

<style scoped>
  a, a:hover, a:active {
    text-decoration: none;
    color: #333943;
  }
  .card-link:hover {
    box-shadow: 0 10px 8px 0 rgba(0,0,0,.1);

    -webkit-transform: translateY(3px);
    -moz-transform: translateY(3px);
    -ms-transform: translateY(3px);
    -o-transform: translateY(3px);
    transform: translateY(3px);
  }
</style>