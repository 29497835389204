import { centralizedAuth as CentralizedAuth } from '@cimpress/simple-auth-wrapper'
const auth = new CentralizedAuth({
  clientID: 'm23DGnp2iIKIVqEhgGrg8CfaTwojbu5u'
})

function getProfile () {
  var profile = JSON.parse(localStorage.getItem('profile'))
  return {
    name: profile.name,
    avatar: profile.picture,
    organization: profile.sub,
    authenticated: true
  }
}

function login (redirect, force = false) {
  const params = { force }
  if (redirect) {
    params.nextUri = redirect
  }
  console.log(params)
  console.log('ensuring authentication!')
  return auth.ensureAuthentication(params)
    .then(data => {
      console.log('ensured authentication!')
      console.log(data)
      if (data === false) {
        console.log('removing token and trying again')
        auth.removeToken()
        return login(redirect, true)
      } else {
        console.log('uhhuh')
        auth.on('tokenExpired', () => {
          console.log('token expired, forcing login')
          auth.removeToken()
          return login(redirect, true)
        })

        return getProfile()
      }
    })
    .catch((err) => {
      console.log(err)
      console.log('Potentially-transient error. Starting over...')
      auth.logout('/')
    })
}

function logout () {
  auth.logout('/')
}

export default { login, logout }
