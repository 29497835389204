<template>
  <div class="row">
    <h1>{{title}}</h1>
    <info-card v-for="service in services" :key="service.name" v-bind:item="service" v-bind:clickCallback="clickHandler"></info-card>
  </div>
</template>

<script>
import { InfoCard } from '@cimpress-technology/cork-vue-components'

export default {
  name: 'service-list',
  components: {
    InfoCard
  },
  props: {
    sectionName: String,
    services: Array,
    clickCallback: Function
  },
  computed: {
    title: function () {
      return this.sectionName.replace(/_/g, ' ')
    }
  },
  methods: {
    clickHandler (event, link) {
      if (this.clickCallback) {
        this.clickCallback(event, link)
      }
    }
  }
}
</script>

<style scoped>
h1 {
  text-transform: capitalize
}
</style>
