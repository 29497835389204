<template>
  <div class="sidenav" id="sidenav" style="top:0px">
    <nav class="navbar navbar-inverse">
      <span class="hamburger" data-toggle="sidenav">
        <img src="https://static.ux.cimpress.io/mcp-ux-css/1.1/assets/icon_close.svg" alt="">
      </span>
      <a class="navbar-brand" href="#"><img src="https://static.ux.cimpress.io/mcp-ux-css/latest/assets/logo-light.svg" alt="Cimpress Open"></a>
    </nav>
    <div class="list-group">
      <a href="/" class="list-group-item"><i class="fa fa-home"></i>Home</a>
      <a href="mailto:hello@cimpress.cloud" class="list-group-item"><i class="fa fa-envelope"></i>Contact</a>
      <sidenav-section v-for="(items, sectionName) in sections" 
                       v-bind:key = "sectionName" 
                       v-bind:sectionName="sectionName" 
                       v-bind:resources="items">
      </sidenav-section>
    </div>
  </div>
</template>

<script>
import * as jquery from 'jquery'
import SidenavSection from './SidenavSection.vue'
import * as data from '../data/services.json';

export default {
  name: 'side-nav',
  data: function() {
    return {
      sections: {}
    }
  },
  components: {
    SidenavSection
  },
  methods: {
    fetchData: function() {
      jquery.ajax({
        url: '/data/services.json',
        method: 'GET',
        dataType: 'json'
      })
      .then(data => {
        this.sections = data;
      })
      .fail(() => {        
        this.sections = data;
      });
    }
  },
  watch: {
    '$route': 'fetchData'
  },
  mounted() {
    this.fetchData();
  }
}
</script>

<style scoped>
</style>