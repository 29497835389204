<template>
    <div id="mcp-header">
    <nav class="navbar navbar-default">
        <span class="hamburger" data-toggle="sidenav">
          <img src="https://static.ux.cimpress.io/mcp-ux-css/latest/assets/icon_hamburger.svg" alt="">
        </span>
        <a class="navbar-brand dont-push" href="/">
          <img src="https://static.ux.cimpress.io/mcp-ux-css/latest/assets/logo-dark.svg" alt="Cimpress">
        </a>
        <div class="navbar-nav do-push">
          <ul class="nav nav-tabs last-used-service">
            <li v-for="service in lastVisited"
                v-bind:key="service.name">
                <a v-bind:href="service.link"><i v-bind:class="['fa', service.icon]"></i></a>
            </li>
          </ul>
        </div>
        <div class="dropdown mcp-navbar-userbox">
          <a class="dropdown-toggle clickable" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
            <div>
              <div id="username">{{user.name}}</div>
              <div id="organization">{{user.organization}}</div>
            </div>
            <img id="avatar" class="avatar" v-bind:src="user.avatar" alt="">
          </a>
          <ul class="dropdown-menu">
            <li>
              <a id="login-logout" @click="logout()" v-if="this.isLoggedIn">Sign Out</a>
              <a id="login-logout" @click="login()" v-else>Sign In</a>
            </li>
          </ul>
        </div>
      </nav>
      <nav class="navbar subtitle">
        <h1>{{title}}</h1>
        <slot name="links"></slot>
      </nav>
    </div>
</template>

<script>

export default {
  name: 'mcp-header',
  props: {
    user: {
      name: String,
      organization: String,
      authenticated: Boolean,
      avatar: String
    },
    title: String
  },
  computed: {
    isLoggedIn: function() {
      return this.user.authenticated;
    },
    lastVisited: function() {
      let lastVisitedServices = localStorage.getItem('corkLastVisited');
      if(!lastVisitedServices) return [];
      return JSON.parse(lastVisitedServices);
    }
  },
  methods: {
    login() {
      this.$emit('login');
    },
    logout() {
      this.$emit('logout');
    }
  }
}
</script>

<style scoped>
  .dont-push {
    margin-right: 0px !important;
  }
  .do-push {
    margin-right: auto !important;
  }
  .last-used-service li {
    border-right: 1px solid lightgray;
    
  }
</style>