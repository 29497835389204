<template>
<footer>
  <div class="container-fluid">
    <ul>
      <li><a href="/">Home</a></li>
      <li><a href="https://support.cimpress.cloud">Documentation</a></li>
      <li><a href="https://cimpress.cloud">Contact Us</a></li>
    </ul>
    <hr>
    <div class="copyright">{{version}}</div>
  </div>
</footer>
</template>

<script>
export default {
  name: 'mcp-footer',
  props: {
    version: String
  }
}
</script>
