<template>
<div>
  <div class="container">
  <div class="row">
    <h1>Cloud Engineering Team</h1>
    <div class="col-md-12 lead" style="padding-bottom: 20px">
      The Cimpress Cloud Engineering team's mission is to ensure that all technolgoists within Cimpress
      have the tools, services, and training necessary to deliver secure, scalable, and cost-effective
      solutions using Cloud technologies.
    </div>

    <div class="col-md-12 lead" style="padding-bottom: 20px">
    <h3>Contact</h3>
      Email: <a href="mailto:hello@cimpress.cloud">hello@cimpress.cloud</a><br/>
      Other: <a href="https://cimpress.cloud">Cimpress.Cloud</a>
    </div>

    <div>
      <h3>Team Members</h3>
      <div v-for="member in members" v-bind:key="member.email" class="col-md-3" style="text-align:center;padding-bottom: 20px">
        <img v-bind:src="member.image"/>
        <div class="caption">
        {{member.name}}<br/>&lt;<a v-bind:href="emailLink(member.email)">{{member.email}}</a>&gt;
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'team',
  data: function () {
    return {
      members: [
        {
          name: 'Jim Costa',
          email: 'jacosta@cimpress.com',
          image: 'https://s.gravatar.com/avatar/a6167a4818f31a6341e2c52fa00a264f?s=80'
        },
        {
          name: 'Morgan Groom',
          email: 'mgroom@cimpress.com',
          image: 'https://s.gravatar.com/avatar/adbbfefcbdcea3dcb5c8ed8d21b639e8?s=80'
        },
        {
          name: 'Chris LaFlamme',
          email: 'claflamme@cimpress.com',
          image: 'https://s.gravatar.com/avatar/f396a6b5e3594ec6b1e733a578986757?s=80'
        },
        {
          name: 'Matt Machado',
          email: 'mmachado@cimpress.com',
          image: 'https://s.gravatar.com/avatar/2b8a62c8b4cd77ecfd7e6cf166351a7b?s=80'
        },
        {
          name: 'Jordan Mackie',
          email: 'jmackie@cimpress.com',
          image: 'https://s.gravatar.com/avatar/d92eced59c2352afa96a40bbbdb0f14e?s=80'
        },
        {
          name: 'Norm MacLennan',
          email: 'nmaclennan@cimpress.com',
          image: 'https://s.gravatar.com/avatar/2286613d5d466afc873742a2b030a6f2?s=80'
        }
      ]
    }
  },
  methods: {
    emailLink: function (email) {
      return `mailto:${email}`
    }
  }
}
</script>
