<template>
  <div id="app">
      <cork-app v-bind:user="user" @login="login" @logout="logout"
                title="Cork" v-bind:version="version">
        <template slot="links">
          <ul class="nav nav-tabs">
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/about">About</router-link></li>
            <li><router-link to="/team">Team</router-link></li>
            <li><a href="https://cimpress.cloud/" target="_blank"><i class="fa fa-comment"></i> Contact</a></li>
          </ul>
        </template>
        <template slot="content">
          <div style="padding-left:50px;padding-right:50px;">
            <router-view></router-view>
          </div>
        </template>
      </cork-app>
  </div>
</template>

<script>
import { CorkApp, Auth } from '@cimpress-technology/cork-vue-components'

export default {
  name: 'app',
  data: function () {
    return {
      user: {
        name: 'Please Login',
        organization: 'Friend',
        authenticated: false,
        avatar: './img/favicon.png'
      },
      services: [],
      tools: [],
      resources: []
    }
  },
  components: {
    CorkApp
  },
  computed: {
    version: function () {
      return process.env.VUE_APP_CORK_VERSION || '0.0.1-local'
    },
    lastVisited: function () {
      const lastVisitedServices = localStorage.getItem('corkLastVisited')
      if (!lastVisitedServices) return []
      return JSON.parse(lastVisitedServices)
    }
  },
  mounted () {
    console.log('hello doing thing')
    Auth.login()
      .then((profile) => {
        if (profile) this.user = profile
      })
  },
  methods: {
    login () {
      Auth.login()
    },
    logout () {
      Auth.logout()
    }
  }
}
</script>

<style>
#app {
  min-height: 100%;
}
.not-footer
{
  min-height: calc(100vh - 107px);
}
</style>
