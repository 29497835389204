<template>
  <div>
      <span class="list-group-item disabled">{{title}}</span>
      <a v-for="resource in resources" 
         v-bind:key="resource.name" 
         v-bind:href="resource.link" class="list-group-item">
          <i v-bind:class="['fa', resource.icon]"></i>{{resource.name}}
      </a>
  </div>
</template>

<script>
export default {
  name: 'sidenav-section',
  props: {
    sectionName: String,
    resources: Array
  },
  computed: {
    title: function() {
      return this.sectionName.replace(/_/g, ' ');
    }
  }
}
</script>

<style scoped>
span {
  text-transform: capitalize
}
</style>
