<template>
<div class="container">
  <div class="row">
    <h1>About</h1>
  </div>
  <div class="row lead">
    <h3>What is Cork?</h3>
    Cork (a portmanteau of Cloud and Orchestration) is the collective name for the suite of services, tools,
    and resources offered by Cloud Engineering to make it easier to build secure, scalable, and cost-effective
    solutions using Cloud technologies.<br/><br/>
    Cork tools have a specific focus on making it easier to operate across multiple AWS accounts as an individual,
    team, or organization. In short, orchestrating changes across a set of accounts or keeping a handle on the
    operational sprawl caused by having so many accounts.
  </div>
  <div class="row lead">
    <h3>What services are part of Cork? And who is the audience?</h3>
    You can check the <router-link to="/">home page</router-link> for a list of services, tools, and resources
    currently baring the Cork banner. Different services and tools have different audiences. Some are for use
    by anyone on the Internet, some are for use by Cimpress employees, and some are for use only by Cloud
    Engineering.
  </div>
  <div class="row lead">
    <h3>I have feedback about an existing Cork product or have an idea for a new one!</h3>
    That's great! We're always looking to make peoples' lives easier by providing automated, self-service
    solutions to solve problems unique to living in the Cloud. Please <a href="https://cimpress.cloud">contact us</a>
    with any feedback, suggestions, concerns, or ideas that you have and we'll be happy to discuss.<br/><br/>
    Keep in mind that, as we are charged with serving any Cimpress technologist we will sometimes have to
    deprioritize issues that only impact one area of the business in order to deliver the most over-all value.
  </div>
</div>
</template>
<script>
export default {
  name: 'about'
}
</script>
