<template>
<div>
  <intro-notification></intro-notification>
  <service-list v-for="(items, sectionName) in sections"
                v-bind:key = "sectionName"
                v-bind:sectionName="sectionName"
                v-bind:services="items"
                v-bind:clickCallback="lastVisited">
  </service-list>
</div>
</template>

<script>
import IntroNotification from './IntroNotification.vue'
import ServiceList from './ServiceList.vue'
import axios from 'axios'

export default {
  name: 'home',
  components: {
    IntroNotification,
    ServiceList
  },
  data: function () {
    return {
      sections: {}
    }
  },
  methods: {
    lastVisited (e, l) {
      let lastVisited = localStorage.getItem('corkLastVisited')
      if (lastVisited) {
        lastVisited = JSON.parse(lastVisited)
      } else {
        lastVisited = []
      }

      let thisService = {}
      for (const section in this.sections) {
        for (const service of this.sections[section]) {
          if (service.link === l) {
            thisService = service
          }
        }
      }

      const index = lastVisited.findIndex(v => v.name === thisService.name)
      if (index > -1) {
        lastVisited.splice(index, 1)
      }

      lastVisited.unshift(thisService)
      if (lastVisited.length > 4) {
        lastVisited.pop()
      }

      localStorage.setItem('corkLastVisited', JSON.stringify(lastVisited))
    }
  },
  created () {
    axios({
      url: '/data/services.json',
      method: 'GET',
      dataType: 'json'
    })
      .then(data => {
        this.sections = data.data
      })
  }
}
</script>
