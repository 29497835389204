<template>
  <div>
    <div class="body-content">
    <mcp-header v-bind:user="user" :title="title" @login="login" @logout="logout">
      <template slot="links">
        <slot name="links"></slot>
      </template>
    </mcp-header>
    <side-nav></side-nav>
    <slot name="content"></slot>
    </div>
    <mcp-footer v-bind:version="version"></mcp-footer>
  </div>
</template>

<script>
import McpHeader from './McpHeader.vue'
import McpFooter from './McpFooter.vue'
import SideNav from './SideNav.vue'

export default {
  name: 'cork-app',
  components: {
    McpHeader,
    McpFooter,
    SideNav
  },
  props: {
    user: Object,
    title: String,
    version: String
  },
  methods: {
    login() {
      this.$emit('login');
    },
    logout() {
      this.$emit('logout');
    }
  }
}
</script>

<style>
  @import 'https://static.ux.cimpress.io/mcp-ux-css/1.1/css/mcp-ux-css.min.css';
  .body-content
  {
    min-height: calc(100vh - 107px);
  }
</style>
