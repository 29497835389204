<template>
<div class="alert alert-info">
  <h4>Hey there!</h4> <b>Cork</b> is a collection of tools and services provided by Cloud Engineering for use by any technologist in Cimpress (or in some cases the world)
  to help them deliver secure, scalable, and cost-effective solutions using Cloud technologies.
</div>
</template>

<script>
export default {
  name: 'intro-notification'
}
</script>

<style scoped>
</style>
